import Panel from 'components/Panel';
import UserBlock from 'components/UserBlock';
import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { bars } from 'react-icons-kit/fa/bars';
import './menu.css';
import image from '../../assets/img/prv.svg';

function Menu(props) {
  const [closed, setclosed] = useState(true);
  return (
    <div className="navBar" style={{ boxShadow: '0 0 10px 2px gray', padding: '10px' }}>
      <div style={{paddingLeft:"15px"}}>
        <Icon
          onClick={() => setclosed(!closed)}
          icon={bars}
          size={23}
          style={{ color: '#f26e1d', position:"absolute", left:"10px",top:"15px" }}
        />
        <img src="https://www.billhwangdefi.co/images/logo-dark.PNG" style={{width:"200px", marginLeft:"15px"}} />
      </div>
      <span style={{ listStyle: 'none', display: 'inline', float: 'right', clear: 'both' }}>
        <UserBlock
          account={props.address}
          connected={props.connected}
          connectWallet={props.connectWallet}
          disconnectWallet={props.disconnectWallet}
        />
      </span>

      <Panel closed={closed} setclosed={() => setclosed(false)} />
    </div>
  );
}

export default Menu;
