import React from 'react';

import './panel.css';

import List from '@material-ui/core/List';
import ListItems from './ListItems';
function Panel(props) {
  return (
    <div
      className={
        props.closed
          ? `${window.screen.width > 900 ? 'panelClosed' : 'panelClosedInsmall'}`
          : 'panel'
      }
    >
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItems closed={props.closed} setclosed ={() => props.setclosed()} />
      </List>
    </div>
  );
}

export default Panel;
