import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = isNightMode =>
  createMuiTheme({
    palette: {
      type: isNightMode ? 'dark' : 'light',
      background: {
        default: '#636363',
        paper: '#636363',
        primary:  '#636363',
        secondary: '#636363',
        extra:  '#636363',
        dark:  '#999',
        paused: '#FCE57E',
        retired:'#e57373',
        hover:  '#EFE6DC',
        border:  '#DED9D5',
      },
      primary: {
        main:  '#000',
      },
      secondary: {
        main: '#F8F2EC',
      },
      text: {
        primary: '#f26e1d',
        secondary: '#f26e1d',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color:  '#f26e1d',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color:  '#f26e1d',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color:  '#f26e1d',
        },
        colorSecondary: {
          color: '#f26e1d',
        },
      },
    },
  });

export default createTheme;
