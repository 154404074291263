export const bscPools = [
 

  {
    id: "cakev2-busd-bnb",
    logo: "cake-pairs/busd-bnb.svg",
    name: "BUSD-BNB",
    token: "Cake BUSD-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "BILL-BUSD-BNB",
    earnedTokenAddress: "0x2ce7F17bf0726634aC8C725c3888BffC0dD91ca1",
    earnContractAddress: "0x2ce7F17bf0726634aC8C725c3888BffC0dD91ca1",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-busd-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["BUSD", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },



  {
    id: "cakev2-ada-bnb",
    logo: "cake-pairs/ada-bnb.svg",
    name: "ADA-BNB",
    token: "Cake ADA-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x28415ff2C35b65B9E5c7de82126b4015ab9d031F",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-ADA-BNB",
    earnedTokenAddress: "0x94Af39Bb0E3C5A0C66e04e53538978336f85C717",
    earnContractAddress: "0x94Af39Bb0E3C5A0C66e04e53538978336f85C717",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-ada-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },



  {
    id: "cakev2-alpaca-busd",
    logo: "cake-pairs/alpaca-busd.svg",
    name: "ALPACA-BUSD",
    token: "Cake ALPACA-BUSD LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x7752e1FA9F3a2e860856458517008558DEb989e3",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-ALPACA-BUSD",
    earnedTokenAddress: "0x3b0D755Da2f5bfB830Bd6c394AC7A8c4CCF8B153",
    earnContractAddress: "0x3b0D755Da2f5bfB830Bd6c394AC7A8c4CCF8B153",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-alpaca-busd",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["ALPACA", "BUSD"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },




  {
    id: "cakev2-cake-bnb",
    logo: "cake-pairs/cake-bnb.svg",
    name: "CAKE-BNB",
    token: "CAKE-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "BILL-CAKE-BNB",
    earnedTokenAddress: "0x4F322E8cAd226f986D09600154420Cf1a5845DB4",
    earnContractAddress: "0x4F322E8cAd226f986D09600154420Cf1a5845DB4",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-cake-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  



  
  {
    id: "cakev2-eth-bnb",
    logo: "cake-pairs/eth-bnb.svg",
    name: "ETH-BNB",
    token: "Cake ETH-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-ETH-BNB",
    earnedTokenAddress: "0x1Da121152C4FE1aB5915C440aCDbFD7e22A5a65a",
    earnContractAddress: "0x1Da121152C4FE1aB5915C440aCDbFD7e22A5a65a",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-eth-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },







  {
    id: "cakev2-bunny-bnb",
    logo: "cake-pairs/bunny-bnb.svg",
    name: "BUNNY-BNB",
    token: "CAKE BUNNY-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x5aFEf8567414F29f0f927A0F2787b188624c10E2",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-BUNNY-BNB",
    earnedTokenAddress: "0x6250F45f02fBc0e8016Fa258767B390A6f2b9DAa",
    earnContractAddress: "0x6250F45f02fBc0e8016Fa258767B390A6f2b9DAa",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-bunny-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },




  {
    id: "ape-bifi-bnb",
    logo: "ape/matic-bnb.svg",
    name: "BIFI-BNB",
    token: "BIBI-BNB LP",
    tokenDescription: "APESWAP",
    tokenAddress: "0xDDd3f9d5Bd347c55D18752c0C2075698EC657750",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GBILL-BIFI-BNB",
    earnedTokenAddress: "0x81fC629B68C020C6BE6322A75bca80F7F24F726D",
    earnContractAddress: "0x81fC629B68C020C6BE6322A75bca80F7F24F726D",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-pacoca-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["BIFI", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://app.apeswap.finance/swap/0x55671114d774ee99d653d6c12460c780a67f1d18",
    addLiquidityUrl: "https://app.apeswap.finance/add/0x55671114d774ee99d653d6c12460c780a67f1d18/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  },



  {
    id: "ape-banana-bnb",
    logo: "ape/banana-bnb.svg",
    name: "BANANA-BNB",
    token: "BANANA-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GBILL-BANANA-BNB",
    earnedTokenAddress: "0x2e7c6cf3F91af9b14E38AA7C4Bb81Eb41D64f09F",
    earnContractAddress: "0x2e7c6cf3F91af9b14E38AA7C4Bb81Eb41D64f09F",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-banana-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["BANANA", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },


  {
    id: "ape-matic-bnb",
    logo: "ape/matic-bnb.svg",
    name: "MATIC-BNB",
    token: "MATIC-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x29A4A3D77c010CE100A45831BF7e798f0f0B325D",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GBILL-MATIC-BNB",
    earnedTokenAddress: "0x4752D4a7c6e26266f509786F0C22aA955e673196",
    earnContractAddress: "0x4752D4a7c6e26266f509786F0C22aA955e673196",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-matic-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["MATIC", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },




  {
    id: "cakev2-xvs-bnb",
    logo: "cake-pairs/xvs-bnb.svg",
    name: "XVS-BNB",
    token: "XVS-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x9e199da6f87E09a290724EbA866eEdae2e971A0b",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-XVS-BNB",
    earnedTokenAddress: "0x19a73748eAB0A0bc505a79Ad596670F94f5CFB86",
    earnContractAddress: "0x19a73748eAB0A0bc505a79Ad596670F94f5CFB86",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-xvs-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
 
  
  {
    id: "ape-pacoca-bnb",
    logo: "ape/matic-bnb.svg",
    name: "PACOCA-BNB ",
    token: "PACOCA-BNB LP",
    tokenDescription: "PACOCA  (8% Deposit Fee)",
    tokenAddress: "0xB9C7049CB298035640E7B6dB219E68C348B976b7",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GBILL-PACOCA-BNB",
    earnedTokenAddress: "0x77d27B363d896EB913159397E30c35D82ca5EB87",
    earnContractAddress: "0x77d27B363d896EB913159397E30c35D82ca5EB87",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-pacoca-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PACOCA",
    assets: ["PACOCA", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.cafeswap.finance/#/swap/0x55671114d774ee99d653d6c12460c780a67f1d18",
    addLiquidityUrl: "https://dex.cafeswap.finance/#/add/0x55671114d774ee99d653d6c12460c780a67f1d18/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  },


  //============================

 






  // // {
  // //   id: "cake-cake",
  // //   logo: "single-assets/CAKE.png",
  // //   name: "Cake",
  // //   token: "Cake",
  // //   tokenDescription: "PancakeSwap",
  // //   tokenAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
  // //   tokenDecimals: 18,
  // //   tokenDescriptionUrl:
  // //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  // //   earnedToken: "gBILL-cake",
  // //   earnedTokenAddress: "0x663f2999F2B439290Da3462DF9bb0dBed0D4eEC2",
  // //   earnContractAddress: "0x663f2999F2B439290Da3462DF9bb0dBed0D4eEC2",
  // //   pricePerFullShare: 1,
  // //   tvl: 0,
  // //   oracle: "tokens",
  // //   oracleId: "CAKE",
  // //   oraclePrice: 0,
  // //   depositsPaused: false ,
  // //   status: "active",
  // //   platform: "PancakeSwap",
  // //   assets: ["CAKE"],
  // //   callFee: 0.5,
  // //   buyTokenUrl: "https://exchange.pancakeswap.finance/#/swap",
  // //   addLiquidityUrl: "https://exchange.pancakeswap.finance/#/add/BNB/",
  // // },


  // // {
  // //   id: "banana-banana",
  // //   logo: "single-assets/BANANA.svg",
  // //   name: "BANANA",
  // //   token: "BANANA",
  // //   tokenDescription: "Apeswap",
  // //   tokenAddress: "0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95",
  // //   tokenDecimals: 18,
  // //   tokenDescriptionUrl:
  // //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  // //   earnedToken: "gBILL-cake",
  // //   earnedTokenAddress: "0x57dE8F39F9D5923289E7484CD184c80E40B328b9",
  // //   earnContractAddress: "0x57dE8F39F9D5923289E7484CD184c80E40B328b9",
  // //   pricePerFullShare: 1,
  // //   tvl: 0,
  // //   oracle: "tokens",
  // //   oracleId: "BANANA",
  // //   oraclePrice: 0,
  // //   depositsPaused: false ,
  // //   status: "active",
  // //   platform: "Apeswap",
  // //   assets: ["BANANA"],
  // //   callFee: 0.5,
  // //   buyTokenUrl: "https://exchange.apeswap.finance/#/swap",
  // //   addLiquidityUrl: "https://exchange.apeswap.finance/#/add/BNB/",
  // // },


  // {
  //   id: "BILL-prv1",
  //   logo: "single-assets/PRV.png",
  //   name: "PRV (Discontinued)",
  //   token: "PRV",
  //   tokenDescription: "PrivacySwap",
  //   tokenAddress: "0x7762A14082Ab475C06D3868B385E46aE27017231",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "1gBILL-prv",
  //   earnedTokenAddress: "0xea1cfa1d350392c2cd10deabbfbda700637677eb",
  //   earnContractAddress: "0xea1cfa1d350392c2cd10deabbfbda700637677eb",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "tokens",
  //   oracleId: "PRV",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "PrivacySwap",
  //   assets: ["PRV"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },




  // {
  //   id: "kebab-btcb",
  //   logo: "kebab/kebab-btcb.png",
  //   name: "KEBAB-BTCB LP",
  //   token: "KEBAB-BTCB LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0x45d8d5d9bd425efc26e3259848bcfefa16f927f0",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-BTCB LP",
  //   earnedTokenAddress: "0x1a460d5dd576ec779fA64d81295Ae19581C65876",
  //   earnContractAddress: "0x1a460d5dd576ec779fA64d81295Ae19581C65876",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-btcb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },
  // {
  //   id: "kebab-busd",
  //   logo: "kebab/kebab-busd.png",
  //   name: "KEBAB-BUSD LP",
  //   token: "KEBAB-BUSD LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0xD51bee2E0A3886289F6D229b6f30c0C2b34fC0Ec",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-BUSD LP",
  //   earnedTokenAddress: "0xbDF4f1a2b92dd0aB4850F141517B664CF8f679a6",
  //   earnContractAddress: "0xbDF4f1a2b92dd0aB4850F141517B664CF8f679a6",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },
  // {
  //   id: "kebab-bnb",
  //   logo: "kebab/kebab-bnb.png",
  //   name: "KEBAB-BNB LP",
  //   token: "KEBAB-BNB LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0x25fd42D82d5c238ee7AA277261AA6CA5BDFE5CD4",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-BNB LP",
  //   earnedTokenAddress: "0x3D5e18d5eD955bc86881fbf0F645cfB76FE881E8",
  //   earnContractAddress: "0x3D5e18d5eD955bc86881fbf0F645cfB76FE881E8",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },
  // {
  //   id: "kebab-btcb-busd",
  //   logo: "kebab/kebab-btcb-busd.png",
  //   name: "BTCB-BUSD LP",
  //   token: "BTCB-BUSD LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0xb8875e207ee8096a929d543c9981c9586992eacb",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-BTCB-BUSD LP",
  //   earnedTokenAddress: "0x4d2301293e1fd691a0534ab3afcb242c0db162de",
  //   earnContractAddress: "0x4d2301293e1fd691a0534ab3afcb242c0db162de",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-btcb-busd",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },


  // // {
  // //   id: "kebab-eth-busd",
  // //   logo: "kebab/kebab-eth-busd.png",
  // //   name: "ETH-BUSD LP",
  // //   token: "ETH-BUSD LP",
  // //   tokenDescription: "Kebab Finance",
  // //   tokenAddress: "0x7380E10F5C5f9DFF4857de3cf9c39Bb16F4C6dcf",
  // //   tokenDecimals: 18,
  // //   tokenDescriptionUrl:
  // //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  // //   earnedToken: "GBILL-KEBAB-ETH-BUSD LP",
  // //   earnedTokenAddress: "0x678e5f70b6b582dfADB3dBD68AF17801d34555c5",
  // //   earnContractAddress: "0x678e5f70b6b582dfADB3dBD68AF17801d34555c5",
  // //   pricePerFullShare: 1,
  // //   tvl: 0,
  // //   oracle: "lps",
  // //   oracleId: "kebab-eth-busd",
  // //   oraclePrice: 0,
  // //   depositsPaused: true,
  // //   status: "active",
  // //   platform: "Kebab Finance",
  // //   assets: ["CAKE", "BNB"],
  // //   callFee: 0.5,
  // //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  // //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // // },
 
 
  // {
  //   id: "kebab-dot-busd",
  //   logo: "kebab/kebab-dot-busd.png",
  //   name: "DOT-BUSD LP",
  //   token: "DOT-BUSD LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0x54C1ec2f543966953F2F7564692606EA7D5a184e",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-DOT-BUSD LP",
  //   earnedTokenAddress: "0x66fd704a0104Dc9d8BCB3c2A0Af85e0dfdc99ddd",
  //   earnContractAddress: "0x66fd704a0104Dc9d8BCB3c2A0Af85e0dfdc99ddd",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-dot-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },




  // // {
  // //   id: "kebab-doge-busd",
  // //   logo: "kebab/kebab-doge-busd.png",
  // //   name: "DOGE-BUSD LP",
  // //   token: "DOGE-BUSD LP",
  // //   tokenDescription: "Kebab Finance",
  // //   tokenAddress: "0x1Efcb446bFa553A2EB2fff99c9F76962be6ECAC3",
  // //   tokenDecimals: 18,
  // //   tokenDescriptionUrl:
  // //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  // //   earnedToken: "GBILL-KEBAB-DOGE-BUSD LP",
  // //   earnedTokenAddress: "0xD88cfBa0E67F626c54ca07395dbe4260b2d0535a",
  // //   earnContractAddress: "0xD88cfBa0E67F626c54ca07395dbe4260b2d0535a",
  // //   pricePerFullShare: 1,
  // //   tvl: 0,
  // //   oracle: "lps",
  // //   oracleId: "kebab-doge-busd",
  // //   oraclePrice: 0,
  // //   depositsPaused: true,
  // //   status: "active",
  // //   platform: "Kebab Finance",
  // //   assets: ["CAKE", "BNB"],
  // //   callFee: 0.5,
  // //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  // //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // // },
  // {
  //   id: "kebab-cake-busd",
  //   logo: "kebab/kebab-cake-busd.png",
  //   name: "CAKE-BUSD LP",
  //   token: "CAKE-BUSD LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0x0Ed8E0A2D99643e1e65CCA22Ed4424090B8B7458",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-CAKE-BUSD LP",
  //   earnedTokenAddress: "0x1FccB8AADd2F735dDACc388F164765D9b316c607",
  //   earnContractAddress: "0x1FccB8AADd2F735dDACc388F164765D9b316c607",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-cake-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },
  // {
  //   id: "kebab-usdt-busd",
  //   logo: "kebab/kebab-usdt-busd.png",
  //   name: "USDT-BUSD LP",
  //   token: "USDT-BUSD LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-USDT-BUSD LP",
  //   earnedTokenAddress: "0xDBe96785460dd62E572bc7a53f7dd2A862E21462",
  //   earnContractAddress: "0xDBe96785460dd62E572bc7a53f7dd2A862E21462",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-usdt-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },
  // {
  //   id: "kebab-usdc-busd",
  //   logo: "kebab/kebab-usdc-busd.png",
  //   name: "USDC-BUSD LP",
  //   token: "USDC-BUSD LP",
  //   tokenDescription: "Kebab Finance",
  //   tokenAddress: "0x680Dd100E4b394Bda26A59dD5c119A391e747d18",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-KEBAB-USDC-BUSD LP",
  //   earnedTokenAddress: "0xF061B1b95BdD28129e153260A0cb8c1dBeEae8b1",
  //   earnContractAddress: "0xF061B1b95BdD28129e153260A0cb8c1dBeEae8b1",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "kebab-usdc-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Kebab Finance",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.kebabfinance.com/#/swap",
  //   addLiquidityUrl: "https://exchange.kebabfinance.com/#/add/BNB/",
  // },


  // //==============

 
  // {
  //   id: "dopple-dop-bnb",
  //   logo: "cake-pairs/dop-bnb.svg",
  //   name: "DOP-BNB",
  //   token: "DOP-BNB LP",
  //   tokenDescription: "Dopple",
  //   tokenAddress: "0x64de2eb6Af1C101f4053BE1DEa89Cb8455A64f0D",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "BILL-DOP-BNB",
  //   earnedTokenAddress: "0x594f8DB55e13D59480e9e3Dc4588C9428a39C4f9",
  //   earnContractAddress: "0x594f8DB55e13D59480e9e3Dc4588C9428a39C4f9",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "dopple-dop-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "Dopple",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://twindex.com/#/swap",
  //   addLiquidityUrl: "https://twindex.com/#/add",
  // },
 
  // {
  //   id: "cakev2-usdc-busd",
  //   logo: "cake-pairs/usdc-busd.svg",
  //   name: "USDC-BUSD",
  //   token: "CAKE USDC-BUSD LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-USDC-BUSD",
  //   earnedTokenAddress: "0xE62a40e6Ab3F2197352eD294b1704fA0a32c2361",
  //   earnContractAddress: "0xE62a40e6Ab3F2197352eD294b1704fA0a32c2361",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-usdc-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-usdt-busd",
  //   logo: "cake-pairs/usdt-busd.svg",
  //   name: "USDT-BUSD",
  //   token: "CAKE USDT-BUSD LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-USDT-BUSD",
  //   earnedTokenAddress: "0xcAbeb44f56Cf8812c74638c4FCBB609F0C72B22a",
  //   earnContractAddress: "0xcAbeb44f56Cf8812c74638c4FCBB609F0C72B22a",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-usdt-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-btcb-bnb",
  //   logo: "cake-pairs/btcb-bnb.svg",
  //   name: "BTCB-BNB",
  //   token: "CAKE BTCB-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-BTCB-BNB",
  //   earnedTokenAddress: "0xCD1F41Ad5FaD382F8d0cA1061197583dE4a89055",
  //   earnContractAddress: "0xCD1F41Ad5FaD382F8d0cA1061197583dE4a89055",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-btcb-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
 
  // {
  //   id: "cakev2-usdt-bnb",
  //   logo: "cake-pairs/usdt-bnb.svg",
  //   name: "USDT-BNB",
  //   token: "CAKE USDT-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-USDT-BNB",
  //   earnedTokenAddress: "0x858Ceb5490Bab4c3dc7A56B4C268DC5a42436118",
  //   earnContractAddress: "0x858Ceb5490Bab4c3dc7A56B4C268DC5a42436118",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-usdt-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },



  // {
  //   id: "cakev2-alice-bnb",
  //   logo: "cake-pairs/alice-bnb.svg",
  //   name: "ALICE-BNB",
  //   token: "CAKE ALICE-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xcAD7019D6d84a3294b0494aEF02e73BD0f2572Eb",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-ALICE-BNB",
  //   earnedTokenAddress: "0x6978DBCfA9E6B3d05e8C44499b750552ca0857Bb",
  //   earnContractAddress: "0x6978DBCfA9E6B3d05e8C44499b750552ca0857Bb",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-alice-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-sushi-eth",
  //   logo: "cake-pairs/sushi-eth.svg",
  //   name: "SUSHI-ETH",
  //   token: "CAKE SUSHI-ETH LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-SUSHI-ETH",
  //   earnedTokenAddress: "0xA43D01DAc8Fe7730827F18e8484F0eC33a42e396",
  //   earnContractAddress: "0xA43D01DAc8Fe7730827F18e8484F0eC33a42e396",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-sushi-eth",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-ltc-bnb",
  //   logo: "cake-pairs/ltc-bnb.svg",
  //   name: "LTC-BNB",
  //   token: "CAKE LTC-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-LTC-BNB",
  //   earnedTokenAddress: "0xA4f353f3e377497832925cC62A901d76b8cdeaAF",
  //   earnContractAddress: "0xA4f353f3e377497832925cC62A901d76b8cdeaAF",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-ltc-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-reef-bnb",
  //   logo: "cake-pairs/reef-bnb.svg",
  //   name: "REEF-BNB",
  //   token: "CAKE REEF-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xd63b5CecB1f40d626307B92706Df357709D05827",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-REEF-BNB",
  //   earnedTokenAddress: "0xd53BAcf274e55Af038AD4A77E8C4C77e917628E3",
  //   earnContractAddress: "0xd53BAcf274e55Af038AD4A77E8C4C77e917628E3",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-reef-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-uni-bnb",
  //   logo: "cake-pairs/uni-bnb.svg",
  //   name: "UNI-BNB",
  //   token: "CAKE UNI-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x014608E87AF97a054C9a49f81E1473076D51d9a3",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-UNI-BNB",
  //   earnedTokenAddress: "0x53aa6df8fBC2743e11Af6689d7a6f1d502324ea4",
  //   earnContractAddress: "0x53aa6df8fBC2743e11Af6689d7a6f1d502324ea4",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-uni-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-xrp-bnb",
  //   logo: "cake-pairs/xrp-bnb.svg",
  //   name: "XRP-BNB",
  //   token: "CAKE XRP-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-XRP-BNB",
  //   earnedTokenAddress: "0x3bB89b51fbFb8814646A6e1601F052E1F163EF3A",
  //   earnContractAddress: "0x3bB89b51fbFb8814646A6e1601F052E1F163EF3A",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-xrp-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-link-bnb",
  //   logo: "cake-pairs/link-bnb.svg",
  //   name: "LINK-BNB",
  //   token: "CAKE LINK-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x824eb9faDFb377394430d2744fa7C42916DE3eCe",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-LINK-BNB",
  //   earnedTokenAddress: "0xDDfd044f549802E1a90cA3a3DC3aa4322245aDad",
  //   earnContractAddress: "0xDDfd044f549802E1a90cA3a3DC3aa4322245aDad",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-link-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-eos-bnb",
  //   logo: "cake-pairs/eos-bnb.svg",
  //   name: "EOS-BNB",
  //   token: "Cake EOS-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xB6e34b5C65Eda51bb1BD4ea5F79d385Fb94b9504",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-EOS-BNB",
  //   earnedTokenAddress: "0xd877de34A38359C55C8F17585A30e746a2FB5AC1",
  //   earnContractAddress: "0xd877de34A38359C55C8F17585A30e746a2FB5AC1",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-eos-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-dot-bnb",
  //   logo: "cake-pairs/dot-bnb.svg",
  //   name: "DOT-BNB",
  //   token: "Cake DOT-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-DOT-BNB",
  //   earnedTokenAddress: "0x47db1173b53F9326B89f1d9E7605c1D1375b395F",
  //   earnContractAddress: "0x47db1173b53F9326B89f1d9E7605c1D1375b395F",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-dot-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },

  // {
  //   id: "cakev2-trx-bnb",
  //   logo: "cake-pairs/trx-bnb.svg",
  //   name: "TRX-BNB",
  //   token: "Cake TRX-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x3cd338c3BB249B6b3C55799F85a589FEbBBFf9Dd",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-TRX-BNB",
  //   earnedTokenAddress: "0xD720Fc0889D818A4DC51d16CBE309224CDb98781",
  //   earnContractAddress: "0xD720Fc0889D818A4DC51d16CBE309224CDb98781",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-trx-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-zil-bnb",
  //   logo: "cake-pairs/zil-bnb.svg",
  //   name: "ZIL-BNB",
  //   token: "Cake ZIL-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x6A97867a4b7Eb7646ffB1F359ad582e9903aa1C2",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-ZIL-BNB",
  //   earnedTokenAddress: "0x433ee4E3F434c2bb85DE184D28A2C9A8faD77f76",
  //   earnContractAddress: "0x433ee4E3F434c2bb85DE184D28A2C9A8faD77f76",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-zil-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-vai-busd",
  //   logo: "cake-pairs/vai-busd.svg",
  //   name: "VAI-BUSD",
  //   token: "Cake VAI-BUSD LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0x133ee93FE93320e1182923E1a640912eDE17C90C",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-VAI-BUSD",
  //   earnedTokenAddress: "0x26319150BB1f75b894083838A89795E24f5bFB1C",
  //   earnContractAddress: "0x26319150BB1f75b894083838A89795E24f5bFB1C",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-vai-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },


  // {
  //   id: "cakev2-doge-bnb",
  //   logo: "cake-pairs/doge-bnb.svg",
  //   name: "DOGE-BNB",
  //   token: "Cake DOGE-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xac109C8025F272414fd9e2faA805a583708A017f",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-DOGE-BNB",
  //   earnedTokenAddress: "0xD4Df975cF69f388beAe669aB2ad451F3Be55b239",
  //   earnContractAddress: "0xD4Df975cF69f388beAe669aB2ad451F3Be55b239",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-doge-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-bmxx-bnb",
  //   logo: "cake-pairs/bmxx-bnb.svg",
  //   name: "BMXX-BNB",
  //   token: "BMXX-BNB LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xc20A92a1424b29b78DFaF92FD35D4cf8A06419B4",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-BMXX-BNB",
  //   earnedTokenAddress: "0x9b30eaf715ED5E1421D856dF8E0b7eD248866c1d",
  //   earnContractAddress: "0x9b30eaf715ED5E1421D856dF8E0b7eD248866c1d",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-bmxx-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "cakev2-btcb-busd",
  //   logo: "cake-pairs/btcb-busd.svg",
  //   name: "BTCB-BUSD",
  //   token: "BTCB-BUSD LP",
  //   tokenDescription: "PancakeSwap",
  //   tokenAddress: "0xF45cd219aEF8618A92BAa7aD848364a158a24F33",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "XGV-BTCB-BUSD",
  //   earnedTokenAddress: "0x564B3724B524Aec25AA3418778fb55Eb5f917C18",
  //   earnContractAddress: "0x564B3724B524Aec25AA3418778fb55Eb5f917C18",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "cakev2-btcb-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PancakeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },






  // {
  //   id: "ape-banana-busd",
  //   logo: "ape/banana-busd.svg",
  //   name: "BANANA-BUSD",
  //   token: "BANANA-BUSD LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-BANANA-BUSD",
  //   earnedTokenAddress: "0xDAe403c30fe8A5c4Dbb42E2e2CeE572F6cA0EdaB",
  //   earnContractAddress: "0xDAe403c30fe8A5c4Dbb42E2e2CeE572F6cA0EdaB",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-banana-busd",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["BANANA", "BUSD"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-busd-bnb",
  //   logo: "ape/busd-bnb.svg",
  //   name: "BUSD-BNB",
  //   token: "APE BUSD-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x51e6D27FA57373d8d4C256231241053a70Cb1d93",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-BUSD-BNB",
  //   earnedTokenAddress: "0x66f33Cc33cfD4Bd9dFF52355be02637FaDB47F24",
  //   earnContractAddress: "0x66f33Cc33cfD4Bd9dFF52355be02637FaDB47F24",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-busd-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["BUSD", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-eth-bnb",
  //   logo: "ape/eth-bnb.svg",
  //   name: "ETH-BNB",
  //   token: "APE ETH-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xA0C3Ef24414ED9C9B456740128d8E63D016A9e11",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-ETH-BNB",
  //   earnedTokenAddress: "0xcEF2Ac0C1c4cD2b1f6122F836D8D29aFCEECe010",
  //   earnContractAddress: "0xcEF2Ac0C1c4cD2b1f6122F836D8D29aFCEECe010",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-eth-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["ETH", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-auto-bnb",
  //   logo: "ape/auto-bnb.svg",
  //   name: "AUTO-BNB",
  //   token: "AUTO-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x649A5Ad5135B4bd287e5AcA8d41f4d5e1b52af5C",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-AUTO-BNB",
  //   earnedTokenAddress: "0xc23B642Be0f7cC6c44cD3Cab7F469798beE3C04A",
  //   earnContractAddress: "0xc23B642Be0f7cC6c44cD3Cab7F469798beE3C04A",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-auto-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["AUTO", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-ont-bnb",
  //   logo: "ape/ont-bnb.svg",
  //   name: "ONT-BNB",
  //   token: "ONT-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x924D3f2F94618e8Ee837d4C2b8d703F0de12a57e",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-ONT-BNB",
  //   earnedTokenAddress: "0xeF5C9ecd4A021b3195b3191148f8Fe83Ce6d50a4",
  //   earnContractAddress: "0xeF5C9ecd4A021b3195b3191148f8Fe83Ce6d50a4",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-ont-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["ONT", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-xrp-bnb",
  //   logo: "ape/xrp-bnb.svg",
  //   name: "XRP-BNB",
  //   token: "APE XRP-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x6f0f18f5fcC1466ec41c8106689e10BeFE68D1c0",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-XRP-BNB",
  //   earnedTokenAddress: "0xd7f8cCb1AE1b3C77c0Ac7d37bf63f08F15334ccE",
  //   earnContractAddress: "0xd7f8cCb1AE1b3C77c0Ac7d37bf63f08F15334ccE",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-xrp-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["XRP", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-avax-bnb",
  //   logo: "ape/avax-bnb.svg",
  //   name: "AVAX-BNB",
  //   token: "AVAX-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x40aFc7CBd0Dc2bE5860F0035b717d20Afb4827b2",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-AVAX-BNB",
  //   earnedTokenAddress: "0x2B50a48357bf9B1dec4c5a0F835E33df84dE5516",
  //   earnContractAddress: "0x2B50a48357bf9B1dec4c5a0F835E33df84dE5516",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-avax-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["AVAX", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },



  // {
  //   id: "ape-aave-bnb",
  //   logo: "ape/aave-bnb.svg",
  //   name: "AAVE-BNB",
  //   token: "AAVE-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-AAVE-BNB",
  //   earnedTokenAddress: "0x6E840458A8058c70bd3e9Bfd96C0060F3C8F05b1",
  //   earnContractAddress: "0x6E840458A8058c70bd3e9Bfd96C0060F3C8F05b1",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-aave-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["AAVE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-etc-bnb",
  //   logo: "ape/etc-bnb.svg",
  //   name: "ETC-BNB",
  //   token: "ETC-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xDd6C7A955C72B3FFD546d8dadBf7669528d8F785",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-ETC-BNB",
  //   earnedTokenAddress: "0x84b95ed90D09A98c3C6e6ae30Ff8B41979F005e4",
  //   earnContractAddress: "0x84b95ed90D09A98c3C6e6ae30Ff8B41979F005e4",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-etc-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["ETC", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-comp-bnb",
  //   logo: "ape/comp-bnb.svg",
  //   name: "COMP-BNB",
  //   token: "COMP-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xb4c0c621B2eDfE6C22585ebAC56b0e634907B8A7",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-COMP-BNB",
  //   earnedTokenAddress: "0x568712a365d36CDdBf49283D3B81D9f9C56451Ac",
  //   earnContractAddress: "0x568712a365d36CDdBf49283D3B81D9f9C56451Ac",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-comp-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["COMP", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-doge-bnb",
  //   logo: "ape/doge-bnb.svg",
  //   name: "DOGE-BNB",
  //   token: "DOGE-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-DOGE-BNB",
  //   earnedTokenAddress: "0xFDb0f94DB4319205D3aDb14fA0916A632c12E6ad",
  //   earnContractAddress: "0xFDb0f94DB4319205D3aDb14fA0916A632c12E6ad",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-doge-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["DOGE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-ltc-bnb",
  //   logo: "ape/ltc-bnb.svg",
  //   name: "LTC-BNB",
  //   token: "APE LTC-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x0F12362c017Fe5101c7bBa09390f1CB729f5B318",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-LTC-BNB",
  //   earnedTokenAddress: "0x9701C51d9300A989997040F250FB24aF0cAAE98b",
  //   earnContractAddress: "0x9701C51d9300A989997040F250FB24aF0cAAE98b",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-ltc-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["LTC", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-dot-bnb",
  //   logo: "ape/dot-bnb.svg",
  //   name: "DOT-BNB",
  //   token: "DOT-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x21CBb561c5d7D70e9E6Cc42136CB22F07552aEef",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-DOT-BNB",
  //   earnedTokenAddress: "0xae69C9f1f0BB41FD0a62A1A677f76011E6f8E442",
  //   earnContractAddress: "0xae69C9f1f0BB41FD0a62A1A677f76011E6f8E442",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-dot-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["DOT", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-sxp-bnb",
  //   logo: "ape/sxp-bnb.svg",
  //   name: "SXP-BNB",
  //   token: "SXP-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xF726b3e81FA7166B9C2cFd7eB7fe8CcBcb6B1355",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-SXP-BNB",
  //   earnedTokenAddress: "0xe160531A38416FA74649246Be4023fF5b0F53680",
  //   earnContractAddress: "0xe160531A38416FA74649246Be4023fF5b0F53680",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-sxp-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["SXP", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-link-bnb",
  //   logo: "ape/link-bnb.svg",
  //   name: "LINK-BNB",
  //   token: "APE LINK-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x092ADA3818DB7FBb8e0A2124Ff218C5125C1CcE6",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-LINK-BNB",
  //   earnedTokenAddress: "0xCE3B6B32937749532e6D05e9Bc8EB55f173F83A8",
  //   earnContractAddress: "0xCE3B6B32937749532e6D05e9Bc8EB55f173F83A8",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-link-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["LINK", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-ada-bnb",
  //   logo: "ape/ada-bnb.svg",
  //   name: "ADA-BNB",
  //   token: "ADA-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x40d4543887E4170A1A40Cd8dB15A6b297c812Cb1",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-ADA-BNB",
  //   earnedTokenAddress: "0xb84E34B143DdE7062311760949802bE788658c44",
  //   earnContractAddress: "0xb84E34B143DdE7062311760949802bE788658c44",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-ada-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["ADA", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-ada-eth",
  //   logo: "ape/ada-eth.svg",
  //   name: "ADA-ETH",
  //   token: "ADA-ETH LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x61FE209E404166a53Cc627d0ae30A65606315dA7",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-ADA-ETH",
  //   earnedTokenAddress: "0x519dc3568e51fDBB53B3B917c5d1da048CF10A8d",
  //   earnContractAddress: "0x519dc3568e51fDBB53B3B917c5d1da048CF10A8d",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-ada-eth",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["ADA", "ETH"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-iota-bnb",
  //   logo: "ape/iota-bnb.svg",
  //   name: "IOTA-BNB",
  //   token: "IOTA-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x0D70924695B6Ae496F0A74A36bf79d47307dD519",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-IOTA-BNB",
  //   earnedTokenAddress: "0xd995a2003c2CBa227496409380C4C1A4DEdC016d",
  //   earnContractAddress: "0xd995a2003c2CBa227496409380C4C1A4DEdC016d",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-iota-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["IOTA", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-busd-usdc",
  //   logo: "ape/busd-usdc.svg",
  //   name: "BUSD-USDC",
  //   token: "BUSD-USDC LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xC087C78AbaC4A0E900a327444193dBF9BA69058E",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-BUSD-USDC",
  //   earnedTokenAddress: "0x26C29C8C43d3b9A68b5789aAfa70aDf9042cc37E",
  //   earnContractAddress: "0x26C29C8C43d3b9A68b5789aAfa70aDf9042cc37E",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-busd-usdc",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["BUSD", "USDC"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-busd-usdt",
  //   logo: "ape/busd-usdt.svg",
  //   name: "BUSD-USDT",
  //   token: "BUSD-USDT LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x2e707261d086687470B515B320478Eb1C88D49bb",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-BUSD-USDT",
  //   earnedTokenAddress: "0x0Da5798D93103547B29C88687486Be43cfaF1BE2",
  //   earnContractAddress: "0x0Da5798D93103547B29C88687486Be43cfaF1BE2",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-busd-usdt",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["BUSD", "USDT"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-sushi-bnb",
  //   logo: "ape/sushi-bnb.svg",
  //   name: "SUSHI-BNB",
  //   token: "SUSHI-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x1D0C3044eBf055986c52D38b19B5369374E6Bc6A",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-SUSHI-BNB",
  //   earnedTokenAddress: "0x7A739d61F8b96AafAa08Fa3E89b061ef4a440270",
  //   earnContractAddress: "0x7A739d61F8b96AafAa08Fa3E89b061ef4a440270",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-sushi-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["SUSHI", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-sushi-eth",
  //   logo: "ape/sushi-eth.svg",
  //   name: "SUSHI-ETH",
  //   token: "APE SUSHI-ETH LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x044F2b275A344D4edfc3d98e1cb7c02B30e6484e",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-SUSHI-ETH",
  //   earnedTokenAddress: "0x0fA3B1e5da4B38b7Cc686D97bA240e0d5f62D213",
  //   earnContractAddress: "0x0fA3B1e5da4B38b7Cc686D97bA240e0d5f62D213",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-sushi-eth",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["SUSHI", "ETH"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-bake-bnb",
  //   logo: "ape/bake-bnb.svg",
  //   name: "BAKE-BNB",
  //   token: "BAKE-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0xc1C7a1D33B34019F82808F64bA07e77512a13d1A",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-BAKE-BNB",
  //   earnedTokenAddress: "0xF220e7c26128cd22f8d9B67FaC3AEAA380fefc98",
  //   earnContractAddress: "0xF220e7c26128cd22f8d9B67FaC3AEAA380fefc98",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-bake-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["BAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-cake-bnb",
  //   logo: "ape/cake-bnb.svg",
  //   name: "CAKE-BNB",
  //   token: "CAKE-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x60593Abea55e9Ea9d31c1b6473191cD2475a720D",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "ABILL-CAKE-BNB",
  //   earnedTokenAddress: "0xd2C6e5837AeDa6AA6212Ba09321f8eE07053fF30",
  //   earnContractAddress: "0xd2C6e5837AeDa6AA6212Ba09321f8eE07053fF30",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-cake-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },
  // {
  //   id: "ape-btcb-bnb",
  //   logo: "ape/btcb-bnb.svg",
  //   name: "BTCB-BNB",
  //   token: "APE BTCB-BNB LP",
  //   tokenDescription: "ApeSwap",
  //   tokenAddress: "0x1E1aFE9D9c5f290d8F6996dDB190bd111908A43D",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-BTCB-BNB",
  //   earnedTokenAddress: "0xbA1B868DE4f40e375d7e4fB50cbf06883C9DA11b",
  //   earnContractAddress: "0xbA1B868DE4f40e375d7e4fB50cbf06883C9DA11b",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "ape-btcb-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "ApeSwap",
  //   assets: ["BTCB", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://dex.apeswap.finance/#/swap",
  //   addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  // },


  // {
  //   id: "goose-cake-bnb",
  //   logo: "goose/cake-bnb.png",
  //   name: "CAKE-BNB V1 (use GooseDefi)",
  //   token: "CAKE-BNB V1 LP (use GooseDefi)",
  //   tokenDescription: "GooseDefi",
  //   tokenAddress: "0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-CAKE-BNB",
  //   earnedTokenAddress: "0x8FdAa7F63D3BDa4EBBa7b2E6e3c2603CA90B5D2D",
  //   earnContractAddress: "0x8FdAa7F63D3BDa4EBBa7b2E6e3c2603CA90B5D2D",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "goose-cake-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "GooseDefi",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.goosedefi.com/#/swap",
  //   addLiquidityUrl: "https://exchange.goosedefi.com/#/add/BNB/",
  // },
  // {
  //   id: "goose-egg-busd",
  //   logo: "goose/egg-bnb.png",
  //   name: "EGG-BUSD",
  //   token: "EGG-BUSD LP",
  //   tokenDescription: "GooseDefi",
  //   tokenAddress: "0x19e7cbecdd23a16dfa5573df54d98f7caae03019",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-DOP-BUSD",
  //   earnedTokenAddress: "0x133ec3f630f587b3650FF7c3aA6Dd0Ff53010Ec7",
  //   earnContractAddress: "0x133ec3f630f587b3650FF7c3aA6Dd0Ff53010Ec7",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "goose-egg-busd",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "GooseDefi",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.goosedefi.com/#/swap",
  //   addLiquidityUrl: "https://exchange.goosedefi.com/#/add/BNB/",
  // },






  // {
  //   id: "goose-cake-busd",
  //   logo: "goose/cake-busd.png",
  //   name: "CAKE-BUSD",
  //   token: "CAKE-BUSD LP",
  //   tokenDescription: "GooseDefi",
  //   tokenAddress: "0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "BILL-CAKE-BUSD",
  //   earnedTokenAddress: "0xF59fe41eb01a5430b05d0C33FFeC68A998447197",
  //   earnContractAddress: "0xF59fe41eb01a5430b05d0C33FFeC68A998447197",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "goose-cake-busd",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "GooseDefi",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.goosedefi.com/#/swap",
  //   addLiquidityUrl: "https://exchange.goosedefi.com/#/add/BNB/",
  // },


  // {
  //   id: "goose-egg-bnb",
  //   logo: "goose/egg-bnb.png",
  //   name: "EGG-BNB",
  //   token: "EGG-BNB LP",
  //   tokenDescription: "GooseDefi",
  //   tokenAddress: "0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "GBILL-EGG-BNB",
  //   earnedTokenAddress: "0x2a29daBF56Fe6C699129e8CdEc2c1845545850E6",
  //   earnContractAddress: "0x2a29daBF56Fe6C699129e8CdEc2c1845545850E6",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "goose-egg-bnb",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "GooseDefi",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.goosedefi.com/#/swap",
  //   addLiquidityUrl: "https://exchange.goosedefi.com/#/add/BNB/",
  // },
  // {
  //   id: "dopple-dop-busd",
  //   logo: "cake-pairs/dop-busd.svg",
  //   name: "DOP-BUSD (Discontinue)",
  //   token: "DOP-BUSD LP",
  //   tokenDescription: "Dopple",
  //   tokenAddress: "0xC789F6C658809eED4d1769a46fc7BCe5dbB8316E",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "BILL-DOP-BUSD",
  //   earnedTokenAddress: "0xd7325983a6208040E9b10Ebbe7eA7e9D4e7Bb5a1",
  //   earnContractAddress: "0xd7325983a6208040E9b10Ebbe7eA7e9D4e7Bb5a1",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "lps",
  //   oracleId: "dopple-dop-busd",
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: "active",
  //   platform: "Dopple",
  //   assets: ["CAKE", "BNB"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://twindex.com/#/swap",
  //   addLiquidityUrl: "https://twindex.com/#/add",
  // },
];

