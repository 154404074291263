import { Button } from '@material-ui/core';
import React from 'react';

function UserBlock(props) {
  const accountEllipsis = props.account
    ? `${props.account.substring(0, 4)}...${props.account.substring(props.account.length - 4)}`
    : null;
  return (
    <div style={{display:"inline-block"}}>
      {props.account ? (
        <Button
          style={{ borderRadius: '16px', color:"#f26e1d" }}
          onClick={() => {
            props.disconnectWallet();
          }}
        >
          <span style={{color:"#ffff", fontSize:"18px", fontWeight:"bold"}}>{accountEllipsis}</span>
        </Button>
      ) : (
        <Button
          style={{ borderRadius: '16px', background: '#f26e1d', color: '#FFFFFF', display:"inline-block" }}
          onClick={() => {
            props.connectWallet();
          }}
        >
          <span style={{color: '#ffff', fontWeight:"bold"}}>Connect</span>
        </Button>
      )}
    </div>
  );
}

export default UserBlock;
